/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NfpaStandardQueryReq } from '../models/nfpa-standard-query-req';
import { NfpaStandardReq } from '../models/nfpa-standard-req';
import { NfpaStandardRes } from '../models/nfpa-standard-res';

@Injectable({
  providedIn: 'root',
})
export class MasterDataNfpaStandardService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiMasterDataNfpaStandardAllGet
   */
  static readonly ApiMasterDataNfpaStandardAllGetPath = '/api/master-data/nfpa-standard/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataNfpaStandardAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataNfpaStandardAllGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<NfpaStandardRes>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataNfpaStandardService.ApiMasterDataNfpaStandardAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NfpaStandardRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataNfpaStandardAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataNfpaStandardAllGet(params?: {
  }): Observable<Array<NfpaStandardRes>> {

    return this.apiMasterDataNfpaStandardAllGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NfpaStandardRes>>) => r.body as Array<NfpaStandardRes>)
    );
  }

  /**
   * Path part for operation apiMasterDataNfpaStandardQueryPost
   */
  static readonly ApiMasterDataNfpaStandardQueryPostPath = '/api/master-data/nfpa-standard/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataNfpaStandardQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataNfpaStandardQueryPost$Response(params?: {
    body?: NfpaStandardQueryReq
  }): Observable<StrictHttpResponse<Array<NfpaStandardRes>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataNfpaStandardService.ApiMasterDataNfpaStandardQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NfpaStandardRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataNfpaStandardQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataNfpaStandardQueryPost(params?: {
    body?: NfpaStandardQueryReq
  }): Observable<Array<NfpaStandardRes>> {

    return this.apiMasterDataNfpaStandardQueryPost$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NfpaStandardRes>>) => r.body as Array<NfpaStandardRes>)
    );
  }

  /**
   * Path part for operation apiMasterDataNfpaStandardInsertPost
   */
  static readonly ApiMasterDataNfpaStandardInsertPostPath = '/api/master-data/nfpa-standard/insert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataNfpaStandardInsertPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataNfpaStandardInsertPost$Response(params?: {
    body?: NfpaStandardReq
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataNfpaStandardService.ApiMasterDataNfpaStandardInsertPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataNfpaStandardInsertPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataNfpaStandardInsertPost(params?: {
    body?: NfpaStandardReq
  }): Observable<void> {

    return this.apiMasterDataNfpaStandardInsertPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiMasterDataNfpaStandardUpdatePut
   */
  static readonly ApiMasterDataNfpaStandardUpdatePutPath = '/api/master-data/nfpa-standard/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataNfpaStandardUpdatePut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataNfpaStandardUpdatePut$Response(params?: {
    Id?: number;
    body?: NfpaStandardReq
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataNfpaStandardService.ApiMasterDataNfpaStandardUpdatePutPath, 'put');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataNfpaStandardUpdatePut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataNfpaStandardUpdatePut(params?: {
    Id?: number;
    body?: NfpaStandardReq
  }): Observable<void> {

    return this.apiMasterDataNfpaStandardUpdatePut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiMasterDataNfpaStandardDeleteDelete
   */
  static readonly ApiMasterDataNfpaStandardDeleteDeletePath = '/api/master-data/nfpa-standard/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataNfpaStandardDeleteDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataNfpaStandardDeleteDelete$Response(params?: {
    Id?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataNfpaStandardService.ApiMasterDataNfpaStandardDeleteDeletePath, 'delete');
    if (params) {
      rb.query('Id', params.Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataNfpaStandardDeleteDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataNfpaStandardDeleteDelete(params?: {
    Id?: number;
  }): Observable<void> {

    return this.apiMasterDataNfpaStandardDeleteDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
