import { NzModalService } from 'ng-zorro-antd/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { isArray } from 'lodash';

export function alertErr(e: any, modalService: NzModalService, translations?: any) {
  console.log(e);
  if (e instanceof HttpErrorResponse) {
    const err = e as HttpErrorResponse;
    try {

      let errObj;

      if (typeof err.error === 'string') {
        errObj = JSON.parse(err.error);
      }
      else {
        errObj = err.error as any;
      }

      if (errObj.messageKey) {
        if (translations) {
          const message = errObj.messageKey.split('.').reduce((a: any, b: any) => a[b], translations);
          modalService.error({
            nzTitle: 'Error',
            nzContent: message
          });
        }
        else {
          modalService.error({
            nzTitle: 'Error',
            nzContent: errObj.messageKey
          });
        }
      }
      else if (errObj.error) {
        if (errObj.error.message) {
          if (isArray(errObj.error.message)) {
            modalService.error({
              nzTitle: 'Error',
              nzContent: errObj.error.message[0]
            });
          }
          else {
            modalService.error({
              nzTitle: 'Error',
              nzContent: errObj.error.message
            });
          }
        }
        else {
          modalService.error({
            nzTitle: 'Error',
            nzContent: err.message
          });
        }
      }
      else {
        modalService.error({
          nzTitle: 'Error',
          nzContent: err.message
        });
      }
      
    } catch (e) {
      console.log(e);
      if (err.error) {
        modalService.error({
          nzTitle: 'Error',
          nzContent: err.error,
        });
      }
      else {
        modalService.error({
          nzTitle: 'Error',
          nzContent: err.message
        });
      }
    }
  }
  else {
    if (e.error) {
      const err = e as HttpErrorResponse;
      if (err.error.title) {
        modalService.error({
          nzTitle: 'Error',
          nzContent: err.error.title
        });
      }
      else {
        modalService.error({
          nzTitle: 'Error',
          nzContent: err.error
        });
      }
    }
    else {
      modalService.error({
        nzTitle: 'Error',
        nzContent: e
      });
    }
  }
}
