import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { appData } from './app/utils/appInitializer';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

fetch(environment.base_url + "/api/app/data").then(response => response.json()).then(res => {
  console.log(res);
  Object.assign(appData, res);

  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
})




  


