/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { StockActionRes } from '../models/stock-action-res';
import { StockTakeOutOptionDataReq } from '../models/stock-take-out-option-data-req';
import { StockTakeOutOptionDataRes } from '../models/stock-take-out-option-data-res';
import { StockTakeOutReq } from '../models/stock-take-out-req';
import { StockTakeOutValidateRecommendedRecordReq } from '../models/stock-take-out-validate-recommended-record-req';

@Injectable({
  providedIn: 'root',
})
export class StockTakeOutService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiStockTakeOutPost
   */
  static readonly ApiStockTakeOutPostPath = '/api/stock-take-out';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockTakeOutPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockTakeOutPost$Response(params?: {
    body?: StockTakeOutReq
  }): Observable<StrictHttpResponse<StockActionRes>> {

    const rb = new RequestBuilder(this.rootUrl, StockTakeOutService.ApiStockTakeOutPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StockActionRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockTakeOutPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockTakeOutPost(params?: {
    body?: StockTakeOutReq
  }): Observable<StockActionRes> {

    return this.apiStockTakeOutPost$Response(params).pipe(
      map((r: StrictHttpResponse<StockActionRes>) => r.body as StockActionRes)
    );
  }

  /**
   * Path part for operation apiStockTakeOutOptDataPost
   */
  static readonly ApiStockTakeOutOptDataPostPath = '/api/stock-take-out/opt-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockTakeOutOptDataPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockTakeOutOptDataPost$Response(params?: {
    body?: StockTakeOutOptionDataReq
  }): Observable<StrictHttpResponse<StockTakeOutOptionDataRes>> {

    const rb = new RequestBuilder(this.rootUrl, StockTakeOutService.ApiStockTakeOutOptDataPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StockTakeOutOptionDataRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockTakeOutOptDataPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockTakeOutOptDataPost(params?: {
    body?: StockTakeOutOptionDataReq
  }): Observable<StockTakeOutOptionDataRes> {

    return this.apiStockTakeOutOptDataPost$Response(params).pipe(
      map((r: StrictHttpResponse<StockTakeOutOptionDataRes>) => r.body as StockTakeOutOptionDataRes)
    );
  }

  /**
   * Path part for operation apiStockTakeOutValidateRecommendedRecordPost
   */
  static readonly ApiStockTakeOutValidateRecommendedRecordPostPath = '/api/stock-take-out/validate/recommended-record';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockTakeOutValidateRecommendedRecordPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockTakeOutValidateRecommendedRecordPost$Response(params?: {
    body?: StockTakeOutValidateRecommendedRecordReq
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StockTakeOutService.ApiStockTakeOutValidateRecommendedRecordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockTakeOutValidateRecommendedRecordPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockTakeOutValidateRecommendedRecordPost(params?: {
    body?: StockTakeOutValidateRecommendedRecordReq
  }): Observable<boolean> {

    return this.apiStockTakeOutValidateRecommendedRecordPost$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
