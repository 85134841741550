/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountService } from './services/account.service';
import { AppService } from './services/app.service';
import { ApplicationService } from './services/application.service';
import { ChemicalsHistoryService } from './services/chemicals-history.service';
import { ChemicalsRegisterService } from './services/chemicals-register.service';
import { FileService } from './services/file.service';
import { FileApiService } from './services/file-api.service';
import { HomeApiService } from './services/home-api.service';
import { MasterDataAreaService } from './services/master-data-area.service';
import { MasterDataChamicalTypeService } from './services/master-data-chamical-type.service';
import { MasterDataDepartmentService } from './services/master-data-department.service';
import { MasterDataNfpaStandardService } from './services/master-data-nfpa-standard.service';
import { MasterDataUnitService } from './services/master-data-unit.service';
import { MdmService } from './services/mdm.service';
import { MeService } from './services/me.service';
import { MtgghsStandardService } from './services/mtgghs-standard.service';
import { MyTaskService } from './services/my-task.service';
import { OrganizationService } from './services/organization.service';
import { PersistedEmailService } from './services/persisted-email.service';
import { ReportService } from './services/report.service';
import { RoleService } from './services/role.service';
import { ServerService } from './services/server.service';
import { StockActionService } from './services/stock-action.service';
import { StockControlService } from './services/stock-control.service';
import { StockHistoryService } from './services/stock-history.service';
import { StockListingService } from './services/stock-listing.service';
import { StockRecordService } from './services/stock-record.service';
import { StockStickerService } from './services/stock-sticker.service';
import { StockTakeInService } from './services/stock-take-in.service';
import { StockTakeOutService } from './services/stock-take-out.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountService,
    AppService,
    ApplicationService,
    ChemicalsHistoryService,
    ChemicalsRegisterService,
    FileService,
    FileApiService,
    HomeApiService,
    MasterDataAreaService,
    MasterDataChamicalTypeService,
    MasterDataDepartmentService,
    MasterDataNfpaStandardService,
    MasterDataUnitService,
    MdmService,
    MeService,
    MtgghsStandardService,
    MyTaskService,
    OrganizationService,
    PersistedEmailService,
    ReportService,
    RoleService,
    ServerService,
    StockActionService,
    StockControlService,
    StockHistoryService,
    StockListingService,
    StockRecordService,
    StockStickerService,
    StockTakeInService,
    StockTakeOutService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
