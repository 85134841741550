/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChemicalsHistoryOptionDataRes } from '../models/chemicals-history-option-data-res';
import { FileStreamRes } from '../models/file-stream-res';
import { TrnChRegHistoryExportReq } from '../models/trn-ch-reg-history-export-req';
import { TrnChRegHistoryQueryReq } from '../models/trn-ch-reg-history-query-req';
import { TrnChRegHistoryQueryRes } from '../models/trn-ch-reg-history-query-res';

@Injectable({
  providedIn: 'root',
})
export class ChemicalsHistoryService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiChemicalsRegisterOptDataGet
   */
  static readonly ApiChemicalsRegisterOptDataGetPath = '/api/chemicals/register/opt-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChemicalsRegisterOptDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterOptDataGet$Response(params?: {
  }): Observable<StrictHttpResponse<ChemicalsHistoryOptionDataRes>> {

    const rb = new RequestBuilder(this.rootUrl, ChemicalsHistoryService.ApiChemicalsRegisterOptDataGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChemicalsHistoryOptionDataRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiChemicalsRegisterOptDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterOptDataGet(params?: {
  }): Observable<ChemicalsHistoryOptionDataRes> {

    return this.apiChemicalsRegisterOptDataGet$Response(params).pipe(
      map((r: StrictHttpResponse<ChemicalsHistoryOptionDataRes>) => r.body as ChemicalsHistoryOptionDataRes)
    );
  }

  /**
   * Path part for operation apiChemicalsRegisterQueryPost
   */
  static readonly ApiChemicalsRegisterQueryPostPath = '/api/chemicals/register/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChemicalsRegisterQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiChemicalsRegisterQueryPost$Response(params?: {
    body?: TrnChRegHistoryQueryReq
  }): Observable<StrictHttpResponse<TrnChRegHistoryQueryRes>> {

    const rb = new RequestBuilder(this.rootUrl, ChemicalsHistoryService.ApiChemicalsRegisterQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrnChRegHistoryQueryRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiChemicalsRegisterQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiChemicalsRegisterQueryPost(params?: {
    body?: TrnChRegHistoryQueryReq
  }): Observable<TrnChRegHistoryQueryRes> {

    return this.apiChemicalsRegisterQueryPost$Response(params).pipe(
      map((r: StrictHttpResponse<TrnChRegHistoryQueryRes>) => r.body as TrnChRegHistoryQueryRes)
    );
  }

  /**
   * Path part for operation apiChemicalsRegisterExportPost
   */
  static readonly ApiChemicalsRegisterExportPostPath = '/api/chemicals/register/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChemicalsRegisterExportPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiChemicalsRegisterExportPost$Response(params?: {
    body?: TrnChRegHistoryExportReq
  }): Observable<StrictHttpResponse<FileStreamRes>> {

    const rb = new RequestBuilder(this.rootUrl, ChemicalsHistoryService.ApiChemicalsRegisterExportPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileStreamRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiChemicalsRegisterExportPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiChemicalsRegisterExportPost(params?: {
    body?: TrnChRegHistoryExportReq
  }): Observable<FileStreamRes> {

    return this.apiChemicalsRegisterExportPost$Response(params).pipe(
      map((r: StrictHttpResponse<FileStreamRes>) => r.body as FileStreamRes)
    );
  }

}
