import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AccountWithPermisionRes } from '../api/models';

@Injectable({
  providedIn: 'root'
})
export class AuthDataService {

  currentAccount: AccountWithPermisionRes | null = null;

  private subjectLoginDisplay = new Subject<boolean>();
  private subjectAccountInfo = new Subject<AccountWithPermisionRes>();

  constructor() {
    this.subjectLoginDisplay.next(false);
  }

  setLoginDisplay(loginDisplay: boolean) {
    this.subjectLoginDisplay.next(loginDisplay);
  }

  getLoginDisplay() {
    return this.subjectLoginDisplay.asObservable();
  }

  setAccount(account: AccountWithPermisionRes) {
    this.currentAccount = account;
    this.subjectAccountInfo.next(account);
  }

  getAccount() {
    return this.subjectAccountInfo.asObservable();
  }
}
