/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { StockActionRes } from '../models/stock-action-res';
import { StockReTakeInReq } from '../models/stock-re-take-in-req';
import { StockTakeInOptionDataReq } from '../models/stock-take-in-option-data-req';
import { StockTakeInOptionDataRes } from '../models/stock-take-in-option-data-res';
import { StockTakeInReq } from '../models/stock-take-in-req';

@Injectable({
  providedIn: 'root',
})
export class StockTakeInService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiStockTakeInPost
   */
  static readonly ApiStockTakeInPostPath = '/api/stock-take-in';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockTakeInPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockTakeInPost$Response(params?: {
    body?: StockTakeInReq
  }): Observable<StrictHttpResponse<StockActionRes>> {

    const rb = new RequestBuilder(this.rootUrl, StockTakeInService.ApiStockTakeInPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StockActionRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockTakeInPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockTakeInPost(params?: {
    body?: StockTakeInReq
  }): Observable<StockActionRes> {

    return this.apiStockTakeInPost$Response(params).pipe(
      map((r: StrictHttpResponse<StockActionRes>) => r.body as StockActionRes)
    );
  }

  /**
   * Path part for operation apiStockTakeInReTakeInPost
   */
  static readonly ApiStockTakeInReTakeInPostPath = '/api/stock-take-in/re-take-in';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockTakeInReTakeInPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockTakeInReTakeInPost$Response(params?: {
    body?: StockReTakeInReq
  }): Observable<StrictHttpResponse<StockActionRes>> {

    const rb = new RequestBuilder(this.rootUrl, StockTakeInService.ApiStockTakeInReTakeInPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StockActionRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockTakeInReTakeInPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockTakeInReTakeInPost(params?: {
    body?: StockReTakeInReq
  }): Observable<StockActionRes> {

    return this.apiStockTakeInReTakeInPost$Response(params).pipe(
      map((r: StrictHttpResponse<StockActionRes>) => r.body as StockActionRes)
    );
  }

  /**
   * Path part for operation apiStockTakeInOptDataPost
   */
  static readonly ApiStockTakeInOptDataPostPath = '/api/stock-take-in/opt-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockTakeInOptDataPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockTakeInOptDataPost$Response(params?: {
    body?: StockTakeInOptionDataReq
  }): Observable<StrictHttpResponse<StockTakeInOptionDataRes>> {

    const rb = new RequestBuilder(this.rootUrl, StockTakeInService.ApiStockTakeInOptDataPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StockTakeInOptionDataRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockTakeInOptDataPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockTakeInOptDataPost(params?: {
    body?: StockTakeInOptionDataReq
  }): Observable<StockTakeInOptionDataRes> {

    return this.apiStockTakeInOptDataPost$Response(params).pipe(
      map((r: StrictHttpResponse<StockTakeInOptionDataRes>) => r.body as StockTakeInOptionDataRes)
    );
  }

}
