/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CustomMtChemicalTypeRes } from '../models/custom-mt-chemical-type-res';

@Injectable({
  providedIn: 'root',
})
export class MasterDataChamicalTypeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiMasterdataChamicalTypeChamicalTypeDisplayGet
   */
  static readonly ApiMasterdataChamicalTypeChamicalTypeDisplayGetPath = '/api/masterdata/ChamicalType/chamicalTypeDisplay';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataChamicalTypeChamicalTypeDisplayGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataChamicalTypeChamicalTypeDisplayGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CustomMtChemicalTypeRes>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataChamicalTypeService.ApiMasterdataChamicalTypeChamicalTypeDisplayGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CustomMtChemicalTypeRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataChamicalTypeChamicalTypeDisplayGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataChamicalTypeChamicalTypeDisplayGet(params?: {
  }): Observable<Array<CustomMtChemicalTypeRes>> {

    return this.apiMasterdataChamicalTypeChamicalTypeDisplayGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CustomMtChemicalTypeRes>>) => r.body as Array<CustomMtChemicalTypeRes>)
    );
  }

  /**
   * Path part for operation apiMasterdataChamicalTypeDeleteChemicalDelete
   */
  static readonly ApiMasterdataChamicalTypeDeleteChemicalDeletePath = '/api/masterdata/ChamicalType/DeleteChemical';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataChamicalTypeDeleteChemicalDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataChamicalTypeDeleteChemicalDelete$Response(params?: {
    ID?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataChamicalTypeService.ApiMasterdataChamicalTypeDeleteChemicalDeletePath, 'delete');
    if (params) {
      rb.query('ID', params.ID, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataChamicalTypeDeleteChemicalDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataChamicalTypeDeleteChemicalDelete(params?: {
    ID?: number;
  }): Observable<void> {

    return this.apiMasterdataChamicalTypeDeleteChemicalDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiMasterdataChamicalTypeAddPost
   */
  static readonly ApiMasterdataChamicalTypeAddPostPath = '/api/masterdata/ChamicalType/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataChamicalTypeAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataChamicalTypeAddPost$Response(params?: {
    body?: Array<CustomMtChemicalTypeRes>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataChamicalTypeService.ApiMasterdataChamicalTypeAddPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataChamicalTypeAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataChamicalTypeAddPost(params?: {
    body?: Array<CustomMtChemicalTypeRes>
  }): Observable<void> {

    return this.apiMasterdataChamicalTypeAddPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiMasterdataChamicalTypeUpdatePut
   */
  static readonly ApiMasterdataChamicalTypeUpdatePutPath = '/api/masterdata/ChamicalType/Update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataChamicalTypeUpdatePut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataChamicalTypeUpdatePut$Response(params?: {
    body?: Array<CustomMtChemicalTypeRes>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataChamicalTypeService.ApiMasterdataChamicalTypeUpdatePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataChamicalTypeUpdatePut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataChamicalTypeUpdatePut(params?: {
    body?: Array<CustomMtChemicalTypeRes>
  }): Observable<void> {

    return this.apiMasterdataChamicalTypeUpdatePut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
