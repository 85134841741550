/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { StockActionAreaDepartmentRes } from '../models/stock-action-area-department-res';
import { StockActionChRegTakeInRes } from '../models/stock-action-ch-reg-take-in-res';
import { StockActionRecordQueryReq } from '../models/stock-action-record-query-req';
import { StockActionRecordQueryRes } from '../models/stock-action-record-query-res';
import { StockActionRes } from '../models/stock-action-res';

@Injectable({
  providedIn: 'root',
})
export class StockActionService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiStockActionGet
   */
  static readonly ApiStockActionGetPath = '/api/stock-action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockActionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockActionGet$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<StockActionRes>> {

    const rb = new RequestBuilder(this.rootUrl, StockActionService.ApiStockActionGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StockActionRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockActionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockActionGet(params?: {
    id?: string;
  }): Observable<StockActionRes> {

    return this.apiStockActionGet$Response(params).pipe(
      map((r: StrictHttpResponse<StockActionRes>) => r.body as StockActionRes)
    );
  }

  /**
   * Path part for operation apiStockActionOptAreaDepartmentGet
   */
  static readonly ApiStockActionOptAreaDepartmentGetPath = '/api/stock-action/opt/area-department';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockActionOptAreaDepartmentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockActionOptAreaDepartmentGet$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<StockActionAreaDepartmentRes>> {

    const rb = new RequestBuilder(this.rootUrl, StockActionService.ApiStockActionOptAreaDepartmentGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StockActionAreaDepartmentRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockActionOptAreaDepartmentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockActionOptAreaDepartmentGet(params?: {
    id?: number;
  }): Observable<StockActionAreaDepartmentRes> {

    return this.apiStockActionOptAreaDepartmentGet$Response(params).pipe(
      map((r: StrictHttpResponse<StockActionAreaDepartmentRes>) => r.body as StockActionAreaDepartmentRes)
    );
  }

  /**
   * Path part for operation apiStockActionOptDataChemicalRegisterSearchGet
   */
  static readonly ApiStockActionOptDataChemicalRegisterSearchGetPath = '/api/stock-action/opt-data/chemical-register/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockActionOptDataChemicalRegisterSearchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockActionOptDataChemicalRegisterSearchGet$Response(params?: {
    keyword?: string;
    areaDepartmentId?: number;
  }): Observable<StrictHttpResponse<Array<StockActionChRegTakeInRes>>> {

    const rb = new RequestBuilder(this.rootUrl, StockActionService.ApiStockActionOptDataChemicalRegisterSearchGetPath, 'get');
    if (params) {
      rb.query('keyword', params.keyword, {});
      rb.query('areaDepartmentId', params.areaDepartmentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StockActionChRegTakeInRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockActionOptDataChemicalRegisterSearchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockActionOptDataChemicalRegisterSearchGet(params?: {
    keyword?: string;
    areaDepartmentId?: number;
  }): Observable<Array<StockActionChRegTakeInRes>> {

    return this.apiStockActionOptDataChemicalRegisterSearchGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StockActionChRegTakeInRes>>) => r.body as Array<StockActionChRegTakeInRes>)
    );
  }

  /**
   * Path part for operation apiStockActionOptDataStockRecordsInStorePost
   */
  static readonly ApiStockActionOptDataStockRecordsInStorePostPath = '/api/stock-action/opt-data/stock-records/in-store';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockActionOptDataStockRecordsInStorePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockActionOptDataStockRecordsInStorePost$Response(params?: {
    body?: StockActionRecordQueryReq
  }): Observable<StrictHttpResponse<StockActionRecordQueryRes>> {

    const rb = new RequestBuilder(this.rootUrl, StockActionService.ApiStockActionOptDataStockRecordsInStorePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StockActionRecordQueryRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockActionOptDataStockRecordsInStorePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockActionOptDataStockRecordsInStorePost(params?: {
    body?: StockActionRecordQueryReq
  }): Observable<StockActionRecordQueryRes> {

    return this.apiStockActionOptDataStockRecordsInStorePost$Response(params).pipe(
      map((r: StrictHttpResponse<StockActionRecordQueryRes>) => r.body as StockActionRecordQueryRes)
    );
  }

  /**
   * Path part for operation apiStockActionStickerPreparePost
   */
  static readonly ApiStockActionStickerPreparePostPath = '/api/stock-action/sticker-prepare';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockActionStickerPreparePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockActionStickerPreparePost$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, StockActionService.ApiStockActionStickerPreparePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockActionStickerPreparePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockActionStickerPreparePost(params?: {
    body?: Array<string>
  }): Observable<string> {

    return this.apiStockActionStickerPreparePost$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
