/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UnitItem } from '../models/unit-item';
import { UnitCapacityItem } from '../models/unit-capacity-item';
import { UnitPackagingItem } from '../models/unit-packaging-item';

@Injectable({
  providedIn: 'root',
})
export class MasterDataUnitService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiMasterdataUnitCapacityGet
   */
  static readonly ApiMasterdataUnitCapacityGetPath = '/api/masterdata/unit/capacity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataUnitCapacityGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataUnitCapacityGet$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<UnitCapacityItem>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataUnitService.ApiMasterdataUnitCapacityGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UnitCapacityItem>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataUnitCapacityGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataUnitCapacityGet(params?: {
    id?: number;
  }): Observable<UnitCapacityItem> {

    return this.apiMasterdataUnitCapacityGet$Response(params).pipe(
      map((r: StrictHttpResponse<UnitCapacityItem>) => r.body as UnitCapacityItem)
    );
  }

  /**
   * Path part for operation apiMasterdataUnitCapacityPut
   */
  static readonly ApiMasterdataUnitCapacityPutPath = '/api/masterdata/unit/capacity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataUnitCapacityPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataUnitCapacityPut$Response(params?: {
    ID?: number;
    body?: UnitCapacityItem
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataUnitService.ApiMasterdataUnitCapacityPutPath, 'put');
    if (params) {
      rb.query('ID', params.ID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataUnitCapacityPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataUnitCapacityPut(params?: {
    ID?: number;
    body?: UnitCapacityItem
  }): Observable<boolean> {

    return this.apiMasterdataUnitCapacityPut$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiMasterdataUnitCapacityPost
   */
  static readonly ApiMasterdataUnitCapacityPostPath = '/api/masterdata/unit/capacity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataUnitCapacityPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataUnitCapacityPost$Response(params?: {
    body?: UnitCapacityItem
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataUnitService.ApiMasterdataUnitCapacityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataUnitCapacityPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataUnitCapacityPost(params?: {
    body?: UnitCapacityItem
  }): Observable<boolean> {

    return this.apiMasterdataUnitCapacityPost$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiMasterdataUnitCapacityDelete
   */
  static readonly ApiMasterdataUnitCapacityDeletePath = '/api/masterdata/unit/capacity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataUnitCapacityDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataUnitCapacityDelete$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataUnitService.ApiMasterdataUnitCapacityDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataUnitCapacityDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataUnitCapacityDelete(params?: {
    id?: number;
  }): Observable<void> {

    return this.apiMasterdataUnitCapacityDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiMasterdataUnitCapacityQueryGet
   */
  static readonly ApiMasterdataUnitCapacityQueryGetPath = '/api/masterdata/unit/capacityQuery';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataUnitCapacityQueryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataUnitCapacityQueryGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<UnitCapacityItem>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataUnitService.ApiMasterdataUnitCapacityQueryGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UnitCapacityItem>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataUnitCapacityQueryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataUnitCapacityQueryGet(params?: {
  }): Observable<Array<UnitCapacityItem>> {

    return this.apiMasterdataUnitCapacityQueryGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UnitCapacityItem>>) => r.body as Array<UnitCapacityItem>)
    );
  }

  /**
   * Path part for operation apiMasterdataUnitSearchCapacityGet
   */
  static readonly ApiMasterdataUnitSearchCapacityGetPath = '/api/masterdata/unit/SearchCapacity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataUnitSearchCapacityGet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataUnitSearchCapacityGet$Response(params?: {
    body?: UnitItem
  }): Observable<StrictHttpResponse<Array<UnitCapacityItem>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataUnitService.ApiMasterdataUnitSearchCapacityGetPath, 'get');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UnitCapacityItem>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataUnitSearchCapacityGet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataUnitSearchCapacityGet(params?: {
    body?: UnitItem
  }): Observable<Array<UnitCapacityItem>> {

    return this.apiMasterdataUnitSearchCapacityGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UnitCapacityItem>>) => r.body as Array<UnitCapacityItem>)
    );
  }

  /**
   * Path part for operation apiMasterdataUnitPackagingGet
   */
  static readonly ApiMasterdataUnitPackagingGetPath = '/api/masterdata/unit/packaging';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataUnitPackagingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataUnitPackagingGet$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<UnitPackagingItem>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataUnitService.ApiMasterdataUnitPackagingGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UnitPackagingItem>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataUnitPackagingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataUnitPackagingGet(params?: {
    id?: number;
  }): Observable<UnitPackagingItem> {

    return this.apiMasterdataUnitPackagingGet$Response(params).pipe(
      map((r: StrictHttpResponse<UnitPackagingItem>) => r.body as UnitPackagingItem)
    );
  }

  /**
   * Path part for operation apiMasterdataUnitPackagingPut
   */
  static readonly ApiMasterdataUnitPackagingPutPath = '/api/masterdata/unit/packaging';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataUnitPackagingPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataUnitPackagingPut$Response(params?: {
    ID?: number;
    body?: UnitPackagingItem
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataUnitService.ApiMasterdataUnitPackagingPutPath, 'put');
    if (params) {
      rb.query('ID', params.ID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataUnitPackagingPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataUnitPackagingPut(params?: {
    ID?: number;
    body?: UnitPackagingItem
  }): Observable<boolean> {

    return this.apiMasterdataUnitPackagingPut$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiMasterdataUnitPackagingPost
   */
  static readonly ApiMasterdataUnitPackagingPostPath = '/api/masterdata/unit/packaging';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataUnitPackagingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataUnitPackagingPost$Response(params?: {
    body?: UnitPackagingItem
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataUnitService.ApiMasterdataUnitPackagingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataUnitPackagingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataUnitPackagingPost(params?: {
    body?: UnitPackagingItem
  }): Observable<boolean> {

    return this.apiMasterdataUnitPackagingPost$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiMasterdataUnitPackagingDelete
   */
  static readonly ApiMasterdataUnitPackagingDeletePath = '/api/masterdata/unit/packaging';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataUnitPackagingDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataUnitPackagingDelete$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataUnitService.ApiMasterdataUnitPackagingDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataUnitPackagingDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataUnitPackagingDelete(params?: {
    id?: number;
  }): Observable<void> {

    return this.apiMasterdataUnitPackagingDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiMasterdataUnitPackagingQueryGet
   */
  static readonly ApiMasterdataUnitPackagingQueryGetPath = '/api/masterdata/unit/packagingQuery';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataUnitPackagingQueryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataUnitPackagingQueryGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<UnitPackagingItem>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataUnitService.ApiMasterdataUnitPackagingQueryGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UnitPackagingItem>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataUnitPackagingQueryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataUnitPackagingQueryGet(params?: {
  }): Observable<Array<UnitPackagingItem>> {

    return this.apiMasterdataUnitPackagingQueryGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UnitPackagingItem>>) => r.body as Array<UnitPackagingItem>)
    );
  }

  /**
   * Path part for operation apiMasterdataUnitPackagingSearchGet
   */
  static readonly ApiMasterdataUnitPackagingSearchGetPath = '/api/masterdata/unit/packagingSearch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataUnitPackagingSearchGet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataUnitPackagingSearchGet$Response(params?: {
    body?: UnitItem
  }): Observable<StrictHttpResponse<Array<UnitPackagingItem>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataUnitService.ApiMasterdataUnitPackagingSearchGetPath, 'get');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UnitPackagingItem>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataUnitPackagingSearchGet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataUnitPackagingSearchGet(params?: {
    body?: UnitItem
  }): Observable<Array<UnitPackagingItem>> {

    return this.apiMasterdataUnitPackagingSearchGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UnitPackagingItem>>) => r.body as Array<UnitPackagingItem>)
    );
  }

}
