/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FileStreamRes } from '../models/file-stream-res';
import { StockHistoryOptionDataRes } from '../models/stock-history-option-data-res';
import { TrnChStockHistoryExportReq } from '../models/trn-ch-stock-history-export-req';
import { TrnChStockHistoryQueryReq } from '../models/trn-ch-stock-history-query-req';
import { TrnChStockHistoryQueryRes } from '../models/trn-ch-stock-history-query-res';

@Injectable({
  providedIn: 'root',
})
export class StockHistoryService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiStockHistoryOptDataGet
   */
  static readonly ApiStockHistoryOptDataGetPath = '/api/stock/history/opt-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockHistoryOptDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockHistoryOptDataGet$Response(params?: {
  }): Observable<StrictHttpResponse<StockHistoryOptionDataRes>> {

    const rb = new RequestBuilder(this.rootUrl, StockHistoryService.ApiStockHistoryOptDataGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StockHistoryOptionDataRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockHistoryOptDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockHistoryOptDataGet(params?: {
  }): Observable<StockHistoryOptionDataRes> {

    return this.apiStockHistoryOptDataGet$Response(params).pipe(
      map((r: StrictHttpResponse<StockHistoryOptionDataRes>) => r.body as StockHistoryOptionDataRes)
    );
  }

  /**
   * Path part for operation apiStockHistoryQueryPost
   */
  static readonly ApiStockHistoryQueryPostPath = '/api/stock/history/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockHistoryQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockHistoryQueryPost$Response(params?: {
    body?: TrnChStockHistoryQueryReq
  }): Observable<StrictHttpResponse<TrnChStockHistoryQueryRes>> {

    const rb = new RequestBuilder(this.rootUrl, StockHistoryService.ApiStockHistoryQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrnChStockHistoryQueryRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockHistoryQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockHistoryQueryPost(params?: {
    body?: TrnChStockHistoryQueryReq
  }): Observable<TrnChStockHistoryQueryRes> {

    return this.apiStockHistoryQueryPost$Response(params).pipe(
      map((r: StrictHttpResponse<TrnChStockHistoryQueryRes>) => r.body as TrnChStockHistoryQueryRes)
    );
  }

  /**
   * Path part for operation apiStockHistoryExportPost
   */
  static readonly ApiStockHistoryExportPostPath = '/api/stock/history/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockHistoryExportPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockHistoryExportPost$Response(params?: {
    body?: TrnChStockHistoryExportReq
  }): Observable<StrictHttpResponse<FileStreamRes>> {

    const rb = new RequestBuilder(this.rootUrl, StockHistoryService.ApiStockHistoryExportPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileStreamRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockHistoryExportPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockHistoryExportPost(params?: {
    body?: TrnChStockHistoryExportReq
  }): Observable<FileStreamRes> {

    return this.apiStockHistoryExportPost$Response(params).pipe(
      map((r: StrictHttpResponse<FileStreamRes>) => r.body as FileStreamRes)
    );
  }

}
