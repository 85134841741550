import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { en_GB, NzI18nService, th_TH } from 'ng-zorro-antd/i18n';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AccountService } from './api/services';
import { AuthDataService } from './services/auth-data.service';
import { SpinnerService } from './services/spinner.service';
import { action } from './utils/action';
import { appData } from './utils/appInitializer';
import { ACCOUNT_INFO_KEY, CURREN_LANG } from './utils/consts';
import { alertErr } from './utils/httpErrorUtls';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {

  favIcon: HTMLLinkElement = document.querySelector('#appIcon')!;

  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();


  constructor(
    @Inject(MSAL_GUARD_CONFIG)
    private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient,
    private translate: TranslateService,
    private router: Router,
    private modalService: NzModalService,
    private authDataService: AuthDataService,
    private accountService: AccountService,
    private spin: SpinnerService,
    private i18n: NzI18nService,
  ) {
    if (!localStorage.getItem(CURREN_LANG)) {
      localStorage.setItem(CURREN_LANG, 'th');
    }

    this.favIcon.href = appData.setting?.cdnSmartworkUrl + "/images/icons/scg-icon-32x32.ico";

    const currentLang = localStorage.getItem(CURREN_LANG) ?? "th";

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(currentLang);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(currentLang);

    if ("th" === currentLang) {
      this.i18n.setLocale(th_TH);
    }
    else {
      this.i18n.setLocale(en_GB);
    }
  }

  async ngOnInit() {
    this.spin.add("appInit");
    this.isIframe = window !== window.parent && !window.opener;

    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
    )
      .subscribe(async () => {
        this.setLoginDisplay();

        const $acc = await action(this.accountService.apiAccountGet().toPromise());

        if (!$acc.error) {
          if ($acc.response) {
            localStorage.setItem(ACCOUNT_INFO_KEY, JSON.stringify($acc.response));
            this.authDataService.setAccount($acc.response);
          }
          else {
            this.router.navigate(["/warming"]);
          }
        }
        else {
          alertErr($acc.error, this.modalService);
        }
        this.spin.stop("appInit");
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    this.authDataService.setLoginDisplay(this.loginDisplay);
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
