/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { StockRecordSearchRes } from '../models/stock-record-search-res';

@Injectable({
  providedIn: 'root',
})
export class StockRecordService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiStockRecordGet
   */
  static readonly ApiStockRecordGetPath = '/api/stock-record';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockRecordGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockRecordGet$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<StockRecordSearchRes>> {

    const rb = new RequestBuilder(this.rootUrl, StockRecordService.ApiStockRecordGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StockRecordSearchRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockRecordGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockRecordGet(params?: {
    id?: string;
  }): Observable<StockRecordSearchRes> {

    return this.apiStockRecordGet$Response(params).pipe(
      map((r: StrictHttpResponse<StockRecordSearchRes>) => r.body as StockRecordSearchRes)
    );
  }

  /**
   * Path part for operation apiStockRecordSearchByBottleIdGet
   */
  static readonly ApiStockRecordSearchByBottleIdGetPath = '/api/stock-record/search-by-bottle-id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockRecordSearchByBottleIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockRecordSearchByBottleIdGet$Response(params?: {
    bottleId?: string;
  }): Observable<StrictHttpResponse<Array<StockRecordSearchRes>>> {

    const rb = new RequestBuilder(this.rootUrl, StockRecordService.ApiStockRecordSearchByBottleIdGetPath, 'get');
    if (params) {
      rb.query('bottleId', params.bottleId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StockRecordSearchRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockRecordSearchByBottleIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockRecordSearchByBottleIdGet(params?: {
    bottleId?: string;
  }): Observable<Array<StockRecordSearchRes>> {

    return this.apiStockRecordSearchByBottleIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StockRecordSearchRes>>) => r.body as Array<StockRecordSearchRes>)
    );
  }

}
