/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CPermissionRes } from '../models/c-permission-res';
import { RoleEditReq } from '../models/role-edit-req';
import { RoleRes } from '../models/role-res';

@Injectable({
  providedIn: 'root',
})
export class RoleService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiRolePut
   */
  static readonly ApiRolePutPath = '/api/role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRolePut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRolePut$Response(params?: {
    id?: string;
    body?: RoleEditReq
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.ApiRolePutPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRolePut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRolePut(params?: {
    id?: string;
    body?: RoleEditReq
  }): Observable<void> {

    return this.apiRolePut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRolePost
   */
  static readonly ApiRolePostPath = '/api/role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRolePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRolePost$Response(params?: {
    body?: RoleEditReq
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.ApiRolePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRolePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRolePost(params?: {
    body?: RoleEditReq
  }): Observable<void> {

    return this.apiRolePost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRoleDelete
   */
  static readonly ApiRoleDeletePath = '/api/role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoleDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleDelete$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.ApiRoleDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRoleDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleDelete(params?: {
    id?: string;
  }): Observable<void> {

    return this.apiRoleDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRoleAllGet
   */
  static readonly ApiRoleAllGetPath = '/api/role/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoleAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleAllGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<RoleRes>>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.ApiRoleAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RoleRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRoleAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleAllGet(params?: {
  }): Observable<Array<RoleRes>> {

    return this.apiRoleAllGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RoleRes>>) => r.body as Array<RoleRes>)
    );
  }

  /**
   * Path part for operation apiRoleOptPermissionsGet
   */
  static readonly ApiRoleOptPermissionsGetPath = '/api/role/opt/permissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoleOptPermissionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleOptPermissionsGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CPermissionRes>>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.ApiRoleOptPermissionsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CPermissionRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRoleOptPermissionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleOptPermissionsGet(params?: {
  }): Observable<Array<CPermissionRes>> {

    return this.apiRoleOptPermissionsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CPermissionRes>>) => r.body as Array<CPermissionRes>)
    );
  }

  /**
   * Path part for operation apiRoleRegisterDefaultRoleAndPermissionsGet
   */
  static readonly ApiRoleRegisterDefaultRoleAndPermissionsGetPath = '/api/role/register-default-role-and-permissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRoleRegisterDefaultRoleAndPermissionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleRegisterDefaultRoleAndPermissionsGet$Response(params?: {
    secret?: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.ApiRoleRegisterDefaultRoleAndPermissionsGetPath, 'get');
    if (params) {
      rb.query('secret', params.secret, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRoleRegisterDefaultRoleAndPermissionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRoleRegisterDefaultRoleAndPermissionsGet(params?: {
    secret?: string;
  }): Observable<boolean> {

    return this.apiRoleRegisterDefaultRoleAndPermissionsGet$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
