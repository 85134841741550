/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DepartmentReportExportReq } from '../models/department-report-export-req';
import { DepartmentReportQueryCriteriaReq } from '../models/department-report-query-criteria-req';
import { DepartmentReportQueryRes } from '../models/department-report-query-res';
import { FileStreamRes } from '../models/file-stream-res';
import { LegalReportExportReq } from '../models/legal-report-export-req';
import { LegalReportQueryCriteriaReq } from '../models/legal-report-query-criteria-req';
import { LegalReportQueryRes } from '../models/legal-report-query-res';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiReportLegalPost
   */
  static readonly ApiReportLegalPostPath = '/api/report/legal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportLegalPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiReportLegalPost$Response(params?: {
    body?: LegalReportQueryCriteriaReq
  }): Observable<StrictHttpResponse<Array<LegalReportQueryRes>>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.ApiReportLegalPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LegalReportQueryRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportLegalPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiReportLegalPost(params?: {
    body?: LegalReportQueryCriteriaReq
  }): Observable<Array<LegalReportQueryRes>> {

    return this.apiReportLegalPost$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LegalReportQueryRes>>) => r.body as Array<LegalReportQueryRes>)
    );
  }

  /**
   * Path part for operation apiReportDepartmentPost
   */
  static readonly ApiReportDepartmentPostPath = '/api/report/department';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportDepartmentPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiReportDepartmentPost$Response(params?: {
    body?: DepartmentReportQueryCriteriaReq
  }): Observable<StrictHttpResponse<Array<DepartmentReportQueryRes>>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.ApiReportDepartmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DepartmentReportQueryRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportDepartmentPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiReportDepartmentPost(params?: {
    body?: DepartmentReportQueryCriteriaReq
  }): Observable<Array<DepartmentReportQueryRes>> {

    return this.apiReportDepartmentPost$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DepartmentReportQueryRes>>) => r.body as Array<DepartmentReportQueryRes>)
    );
  }

  /**
   * Path part for operation apiReportExportLegalPost
   */
  static readonly ApiReportExportLegalPostPath = '/api/report/export-legal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportExportLegalPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiReportExportLegalPost$Response(params?: {
    body?: LegalReportExportReq
  }): Observable<StrictHttpResponse<FileStreamRes>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.ApiReportExportLegalPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileStreamRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportExportLegalPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiReportExportLegalPost(params?: {
    body?: LegalReportExportReq
  }): Observable<FileStreamRes> {

    return this.apiReportExportLegalPost$Response(params).pipe(
      map((r: StrictHttpResponse<FileStreamRes>) => r.body as FileStreamRes)
    );
  }

  /**
   * Path part for operation apiReportExportDepartmentPost
   */
  static readonly ApiReportExportDepartmentPostPath = '/api/report/export-department';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportExportDepartmentPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiReportExportDepartmentPost$Response(params?: {
    body?: DepartmentReportExportReq
  }): Observable<StrictHttpResponse<FileStreamRes>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.ApiReportExportDepartmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileStreamRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportExportDepartmentPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiReportExportDepartmentPost(params?: {
    body?: DepartmentReportExportReq
  }): Observable<FileStreamRes> {

    return this.apiReportExportDepartmentPost$Response(params).pipe(
      map((r: StrictHttpResponse<FileStreamRes>) => r.body as FileStreamRes)
    );
  }

}
