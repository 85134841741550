/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MtGhsStandardEditReq } from '../models/mt-ghs-standard-edit-req';
import { MtGhsStandardRes } from '../models/mt-ghs-standard-res';

@Injectable({
  providedIn: 'root',
})
export class MtgghsStandardService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiMasterDataGhsStandardAllGet
   */
  static readonly ApiMasterDataGhsStandardAllGetPath = '/api/master-data/ghs-standard/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataGhsStandardAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataGhsStandardAllGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MtGhsStandardRes>>> {

    const rb = new RequestBuilder(this.rootUrl, MtgghsStandardService.ApiMasterDataGhsStandardAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MtGhsStandardRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataGhsStandardAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataGhsStandardAllGet(params?: {
  }): Observable<Array<MtGhsStandardRes>> {

    return this.apiMasterDataGhsStandardAllGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MtGhsStandardRes>>) => r.body as Array<MtGhsStandardRes>)
    );
  }

  /**
   * Path part for operation apiMasterDataGhsStandardSearchGet
   */
  static readonly ApiMasterDataGhsStandardSearchGetPath = '/api/master-data/ghs-standard/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataGhsStandardSearchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataGhsStandardSearchGet$Response(params?: {
    Input?: string;
  }): Observable<StrictHttpResponse<Array<MtGhsStandardRes>>> {

    const rb = new RequestBuilder(this.rootUrl, MtgghsStandardService.ApiMasterDataGhsStandardSearchGetPath, 'get');
    if (params) {
      rb.query('Input', params.Input, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MtGhsStandardRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataGhsStandardSearchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataGhsStandardSearchGet(params?: {
    Input?: string;
  }): Observable<Array<MtGhsStandardRes>> {

    return this.apiMasterDataGhsStandardSearchGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MtGhsStandardRes>>) => r.body as Array<MtGhsStandardRes>)
    );
  }

  /**
   * Path part for operation apiMasterDataGhsStandardPut
   */
  static readonly ApiMasterDataGhsStandardPutPath = '/api/master-data/ghs-standard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataGhsStandardPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataGhsStandardPut$Response(params?: {
    id?: number;
    body?: MtGhsStandardEditReq
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MtgghsStandardService.ApiMasterDataGhsStandardPutPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataGhsStandardPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataGhsStandardPut(params?: {
    id?: number;
    body?: MtGhsStandardEditReq
  }): Observable<void> {

    return this.apiMasterDataGhsStandardPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiMasterDataGhsStandardPost
   */
  static readonly ApiMasterDataGhsStandardPostPath = '/api/master-data/ghs-standard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataGhsStandardPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataGhsStandardPost$Response(params?: {
    body?: MtGhsStandardEditReq
  }): Observable<StrictHttpResponse<MtGhsStandardRes>> {

    const rb = new RequestBuilder(this.rootUrl, MtgghsStandardService.ApiMasterDataGhsStandardPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MtGhsStandardRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataGhsStandardPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataGhsStandardPost(params?: {
    body?: MtGhsStandardEditReq
  }): Observable<MtGhsStandardRes> {

    return this.apiMasterDataGhsStandardPost$Response(params).pipe(
      map((r: StrictHttpResponse<MtGhsStandardRes>) => r.body as MtGhsStandardRes)
    );
  }

  /**
   * Path part for operation apiMasterDataGhsStandardDelete
   */
  static readonly ApiMasterDataGhsStandardDeletePath = '/api/master-data/ghs-standard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataGhsStandardDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataGhsStandardDelete$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MtgghsStandardService.ApiMasterDataGhsStandardDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataGhsStandardDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataGhsStandardDelete(params?: {
    id?: number;
  }): Observable<void> {

    return this.apiMasterDataGhsStandardDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
