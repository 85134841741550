export const action = <T>(promise: Promise<T>) => {
  return promise.then((response) => {
    return {
      error: null,
      response: response,
    }
  })
    .catch((err) => {
      return {
        error: err,
        response: null,
      }
    })
}
