/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DbTestReq } from '../models/db-test-req';
import { HttpTestReq } from '../models/http-test-req';
import { MailReq } from '../models/mail-req';

@Injectable({
  providedIn: 'root',
})
export class ServerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiServerAppSettingGet
   */
  static readonly ApiServerAppSettingGetPath = '/api/Server/app-setting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerAppSettingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerAppSettingGet$Response(params?: {
    secret?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ServerService.ApiServerAppSettingGetPath, 'get');
    if (params) {
      rb.query('secret', params.secret, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServerAppSettingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerAppSettingGet(params?: {
    secret?: string;
  }): Observable<void> {

    return this.apiServerAppSettingGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiServerConfigReloadGet
   */
  static readonly ApiServerConfigReloadGetPath = '/api/Server/config-reload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerConfigReloadGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerConfigReloadGet$Response(params?: {
    secret?: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ServerService.ApiServerConfigReloadGetPath, 'get');
    if (params) {
      rb.query('secret', params.secret, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServerConfigReloadGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerConfigReloadGet(params?: {
    secret?: string;
  }): Observable<boolean> {

    return this.apiServerConfigReloadGet$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiServerDbTestPost
   */
  static readonly ApiServerDbTestPostPath = '/api/Server/db-test';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerDbTestPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiServerDbTestPost$Response(params?: {
    body?: DbTestReq
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ServerService.ApiServerDbTestPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServerDbTestPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiServerDbTestPost(params?: {
    body?: DbTestReq
  }): Observable<string> {

    return this.apiServerDbTestPost$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiServerHttpTestPost
   */
  static readonly ApiServerHttpTestPostPath = '/api/Server/http-test';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerHttpTestPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiServerHttpTestPost$Response(params?: {
    body?: HttpTestReq
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ServerService.ApiServerHttpTestPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServerHttpTestPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiServerHttpTestPost(params?: {
    body?: HttpTestReq
  }): Observable<string> {

    return this.apiServerHttpTestPost$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiServerMailPost
   */
  static readonly ApiServerMailPostPath = '/api/Server/mail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerMailPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiServerMailPost$Response(params?: {
    body?: MailReq
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ServerService.ApiServerMailPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServerMailPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiServerMailPost(params?: {
    body?: MailReq
  }): Observable<string> {

    return this.apiServerMailPost$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiServerMsLoginConfigurationGet
   */
  static readonly ApiServerMsLoginConfigurationGetPath = '/api/Server/ms-login-configuration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerMsLoginConfigurationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerMsLoginConfigurationGet$Response(params?: {
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ServerService.ApiServerMsLoginConfigurationGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServerMsLoginConfigurationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerMsLoginConfigurationGet(params?: {
  }): Observable<boolean> {

    return this.apiServerMsLoginConfigurationGet$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
