/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChainOfCommand } from '../models/chain-of-command';
import { MdmGetTokenReq } from '../models/mdm-get-token-req';
import { MemberInfo } from '../models/member-info';

@Injectable({
  providedIn: 'root',
})
export class MdmService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiMdmCompanySynchronizeGet
   */
  static readonly ApiMdmCompanySynchronizeGetPath = '/api/MDM/company-synchronize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmCompanySynchronizeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmCompanySynchronizeGet$Response(params?: {
    secret?: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmCompanySynchronizeGetPath, 'get');
    if (params) {
      rb.query('secret', params.secret, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmCompanySynchronizeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmCompanySynchronizeGet(params?: {
    secret?: string;
  }): Observable<boolean> {

    return this.apiMdmCompanySynchronizeGet$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiMdmMemberInfoByEmailGet
   */
  static readonly ApiMdmMemberInfoByEmailGetPath = '/api/MDM/MemberInfoByEmail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmMemberInfoByEmailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmMemberInfoByEmailGet$Response(params?: {
    email?: string;
    secret?: string;
  }): Observable<StrictHttpResponse<Array<MemberInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmMemberInfoByEmailGetPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
      rb.query('secret', params.secret, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MemberInfo>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmMemberInfoByEmailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmMemberInfoByEmailGet(params?: {
    email?: string;
    secret?: string;
  }): Observable<Array<MemberInfo>> {

    return this.apiMdmMemberInfoByEmailGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MemberInfo>>) => r.body as Array<MemberInfo>)
    );
  }

  /**
   * Path part for operation apiMdmMembersInfoByStartWithNameGet
   */
  static readonly ApiMdmMembersInfoByStartWithNameGetPath = '/api/MDM/MembersInfoByStartWithName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmMembersInfoByStartWithNameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmMembersInfoByStartWithNameGet$Response(params?: {
    startwith?: string;
    secret?: string;
  }): Observable<StrictHttpResponse<Array<MemberInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmMembersInfoByStartWithNameGetPath, 'get');
    if (params) {
      rb.query('startwith', params.startwith, {});
      rb.query('secret', params.secret, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MemberInfo>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmMembersInfoByStartWithNameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmMembersInfoByStartWithNameGet(params?: {
    startwith?: string;
    secret?: string;
  }): Observable<Array<MemberInfo>> {

    return this.apiMdmMembersInfoByStartWithNameGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MemberInfo>>) => r.body as Array<MemberInfo>)
    );
  }

  /**
   * Path part for operation apiMdmEmployeeOrgChartByOrganizationIdPost
   */
  static readonly ApiMdmEmployeeOrgChartByOrganizationIdPostPath = '/api/MDM/EmployeeOrgChartByOrganizationId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmEmployeeOrgChartByOrganizationIdPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmEmployeeOrgChartByOrganizationIdPost$Response(params?: {
    organizationId?: number;
    secret?: string;
  }): Observable<StrictHttpResponse<Array<ChainOfCommand>>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmEmployeeOrgChartByOrganizationIdPostPath, 'post');
    if (params) {
      rb.query('organizationId', params.organizationId, {});
      rb.query('secret', params.secret, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ChainOfCommand>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmEmployeeOrgChartByOrganizationIdPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmEmployeeOrgChartByOrganizationIdPost(params?: {
    organizationId?: number;
    secret?: string;
  }): Observable<Array<ChainOfCommand>> {

    return this.apiMdmEmployeeOrgChartByOrganizationIdPost$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ChainOfCommand>>) => r.body as Array<ChainOfCommand>)
    );
  }

  /**
   * Path part for operation apiMdmCurrentTokenGet
   */
  static readonly ApiMdmCurrentTokenGetPath = '/api/MDM/CurrentToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmCurrentTokenGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmCurrentTokenGet$Response(params?: {
    secret?: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmCurrentTokenGetPath, 'get');
    if (params) {
      rb.query('secret', params.secret, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmCurrentTokenGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmCurrentTokenGet(params?: {
    secret?: string;
  }): Observable<string> {

    return this.apiMdmCurrentTokenGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiMdmGetTokenGet
   */
  static readonly ApiMdmGetTokenGetPath = '/api/MDM/GetToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmGetTokenGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmGetTokenGet$Response(params?: {
    secret?: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmGetTokenGetPath, 'get');
    if (params) {
      rb.query('secret', params.secret, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmGetTokenGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmGetTokenGet(params?: {
    secret?: string;
  }): Observable<boolean> {

    return this.apiMdmGetTokenGet$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiMdmGetTokenManualPost
   */
  static readonly ApiMdmGetTokenManualPostPath = '/api/MDM/GetToken/Manual';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmGetTokenManualPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMdmGetTokenManualPost$Response(params?: {
    body?: MdmGetTokenReq
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmGetTokenManualPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmGetTokenManualPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMdmGetTokenManualPost(params?: {
    body?: MdmGetTokenReq
  }): Observable<string> {

    return this.apiMdmGetTokenManualPost$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
