/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FileStreamRes } from '../models/file-stream-res';
import { TrnChStockCtrlEditItemReq } from '../models/trn-ch-stock-ctrl-edit-item-req';
import { TrnChStockCtrlExportReq } from '../models/trn-ch-stock-ctrl-export-req';
import { TrnChStockCtrlOptMtUnitOfPackagingRes } from '../models/trn-ch-stock-ctrl-opt-mt-unit-of-packaging-res';
import { TrnChStockCtrlOptTrnChRegRes } from '../models/trn-ch-stock-ctrl-opt-trn-ch-reg-res';
import { TrnChStockCtrlQueryOptMtDepartmentRes } from '../models/trn-ch-stock-ctrl-query-opt-mt-department-res';
import { TrnChStockCtrlQueryOptRes } from '../models/trn-ch-stock-ctrl-query-opt-res';
import { TrnChStockCtrlQueryReq } from '../models/trn-ch-stock-ctrl-query-req';
import { TrnChStockCtrlRes } from '../models/trn-ch-stock-ctrl-res';

@Injectable({
  providedIn: 'root',
})
export class StockControlService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiStockControlOptDataGet
   */
  static readonly ApiStockControlOptDataGetPath = '/api/StockControl/opt-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockControlOptDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockControlOptDataGet$Response(params?: {
  }): Observable<StrictHttpResponse<TrnChStockCtrlQueryOptRes>> {

    const rb = new RequestBuilder(this.rootUrl, StockControlService.ApiStockControlOptDataGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrnChStockCtrlQueryOptRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockControlOptDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockControlOptDataGet(params?: {
  }): Observable<TrnChStockCtrlQueryOptRes> {

    return this.apiStockControlOptDataGet$Response(params).pipe(
      map((r: StrictHttpResponse<TrnChStockCtrlQueryOptRes>) => r.body as TrnChStockCtrlQueryOptRes)
    );
  }

  /**
   * Path part for operation apiStockControlOptDepartmentsGet
   */
  static readonly ApiStockControlOptDepartmentsGetPath = '/api/StockControl/opt/departments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockControlOptDepartmentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockControlOptDepartmentsGet$Response(params?: {
    mtAreaId?: number;
  }): Observable<StrictHttpResponse<Array<TrnChStockCtrlQueryOptMtDepartmentRes>>> {

    const rb = new RequestBuilder(this.rootUrl, StockControlService.ApiStockControlOptDepartmentsGetPath, 'get');
    if (params) {
      rb.query('mtAreaId', params.mtAreaId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TrnChStockCtrlQueryOptMtDepartmentRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockControlOptDepartmentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockControlOptDepartmentsGet(params?: {
    mtAreaId?: number;
  }): Observable<Array<TrnChStockCtrlQueryOptMtDepartmentRes>> {

    return this.apiStockControlOptDepartmentsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TrnChStockCtrlQueryOptMtDepartmentRes>>) => r.body as Array<TrnChStockCtrlQueryOptMtDepartmentRes>)
    );
  }

  /**
   * Path part for operation apiStockControlOptChemicalRegistersGet
   */
  static readonly ApiStockControlOptChemicalRegistersGetPath = '/api/StockControl/opt/chemical-registers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockControlOptChemicalRegistersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockControlOptChemicalRegistersGet$Response(params?: {
    mtAreaId?: number;
    mtDepartmentId?: number;
  }): Observable<StrictHttpResponse<Array<TrnChStockCtrlOptTrnChRegRes>>> {

    const rb = new RequestBuilder(this.rootUrl, StockControlService.ApiStockControlOptChemicalRegistersGetPath, 'get');
    if (params) {
      rb.query('mtAreaId', params.mtAreaId, {});
      rb.query('mtDepartmentId', params.mtDepartmentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TrnChStockCtrlOptTrnChRegRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockControlOptChemicalRegistersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockControlOptChemicalRegistersGet(params?: {
    mtAreaId?: number;
    mtDepartmentId?: number;
  }): Observable<Array<TrnChStockCtrlOptTrnChRegRes>> {

    return this.apiStockControlOptChemicalRegistersGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TrnChStockCtrlOptTrnChRegRes>>) => r.body as Array<TrnChStockCtrlOptTrnChRegRes>)
    );
  }

  /**
   * Path part for operation apiStockControlOptUnitOfPackagingsGet
   */
  static readonly ApiStockControlOptUnitOfPackagingsGetPath = '/api/StockControl/opt/unit-of-packagings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockControlOptUnitOfPackagingsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockControlOptUnitOfPackagingsGet$Response(params?: {
    trnChRegId?: string;
  }): Observable<StrictHttpResponse<Array<TrnChStockCtrlOptMtUnitOfPackagingRes>>> {

    const rb = new RequestBuilder(this.rootUrl, StockControlService.ApiStockControlOptUnitOfPackagingsGetPath, 'get');
    if (params) {
      rb.query('trnChRegId', params.trnChRegId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TrnChStockCtrlOptMtUnitOfPackagingRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockControlOptUnitOfPackagingsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockControlOptUnitOfPackagingsGet(params?: {
    trnChRegId?: string;
  }): Observable<Array<TrnChStockCtrlOptMtUnitOfPackagingRes>> {

    return this.apiStockControlOptUnitOfPackagingsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TrnChStockCtrlOptMtUnitOfPackagingRes>>) => r.body as Array<TrnChStockCtrlOptMtUnitOfPackagingRes>)
    );
  }

  /**
   * Path part for operation apiStockControlCountStockGet
   */
  static readonly ApiStockControlCountStockGetPath = '/api/StockControl/count-stock';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockControlCountStockGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockControlCountStockGet$Response(params?: {
    trnChRegId?: string;
    mtUnitOfPackagingId?: number;
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, StockControlService.ApiStockControlCountStockGetPath, 'get');
    if (params) {
      rb.query('trnChRegId', params.trnChRegId, {});
      rb.query('mtUnitOfPackagingId', params.mtUnitOfPackagingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockControlCountStockGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockControlCountStockGet(params?: {
    trnChRegId?: string;
    mtUnitOfPackagingId?: number;
  }): Observable<number> {

    return this.apiStockControlCountStockGet$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation apiStockControlGet
   */
  static readonly ApiStockControlGetPath = '/api/StockControl';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockControlGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockControlGet$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<TrnChStockCtrlRes>> {

    const rb = new RequestBuilder(this.rootUrl, StockControlService.ApiStockControlGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrnChStockCtrlRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockControlGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockControlGet(params?: {
    id?: number;
  }): Observable<TrnChStockCtrlRes> {

    return this.apiStockControlGet$Response(params).pipe(
      map((r: StrictHttpResponse<TrnChStockCtrlRes>) => r.body as TrnChStockCtrlRes)
    );
  }

  /**
   * Path part for operation apiStockControlPut
   */
  static readonly ApiStockControlPutPath = '/api/StockControl';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockControlPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockControlPut$Response(params?: {
    id?: number;
    body?: TrnChStockCtrlEditItemReq
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StockControlService.ApiStockControlPutPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockControlPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockControlPut(params?: {
    id?: number;
    body?: TrnChStockCtrlEditItemReq
  }): Observable<void> {

    return this.apiStockControlPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiStockControlPost
   */
  static readonly ApiStockControlPostPath = '/api/StockControl';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockControlPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockControlPost$Response(params?: {
    body?: TrnChStockCtrlEditItemReq
  }): Observable<StrictHttpResponse<TrnChStockCtrlRes>> {

    const rb = new RequestBuilder(this.rootUrl, StockControlService.ApiStockControlPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrnChStockCtrlRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockControlPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockControlPost(params?: {
    body?: TrnChStockCtrlEditItemReq
  }): Observable<TrnChStockCtrlRes> {

    return this.apiStockControlPost$Response(params).pipe(
      map((r: StrictHttpResponse<TrnChStockCtrlRes>) => r.body as TrnChStockCtrlRes)
    );
  }

  /**
   * Path part for operation apiStockControlDelete
   */
  static readonly ApiStockControlDeletePath = '/api/StockControl';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockControlDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockControlDelete$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StockControlService.ApiStockControlDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockControlDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStockControlDelete(params?: {
    id?: number;
  }): Observable<void> {

    return this.apiStockControlDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiStockControlQueryPost
   */
  static readonly ApiStockControlQueryPostPath = '/api/StockControl/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockControlQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockControlQueryPost$Response(params?: {
    body?: TrnChStockCtrlQueryReq
  }): Observable<StrictHttpResponse<Array<TrnChStockCtrlRes>>> {

    const rb = new RequestBuilder(this.rootUrl, StockControlService.ApiStockControlQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TrnChStockCtrlRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockControlQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockControlQueryPost(params?: {
    body?: TrnChStockCtrlQueryReq
  }): Observable<Array<TrnChStockCtrlRes>> {

    return this.apiStockControlQueryPost$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TrnChStockCtrlRes>>) => r.body as Array<TrnChStockCtrlRes>)
    );
  }

  /**
   * Path part for operation apiStockControlExportPost
   */
  static readonly ApiStockControlExportPostPath = '/api/StockControl/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStockControlExportPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockControlExportPost$Response(params?: {
    body?: TrnChStockCtrlExportReq
  }): Observable<StrictHttpResponse<FileStreamRes>> {

    const rb = new RequestBuilder(this.rootUrl, StockControlService.ApiStockControlExportPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileStreamRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStockControlExportPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiStockControlExportPost(params?: {
    body?: TrnChStockCtrlExportReq
  }): Observable<FileStreamRes> {

    return this.apiStockControlExportPost$Response(params).pipe(
      map((r: StrictHttpResponse<FileStreamRes>) => r.body as FileStreamRes)
    );
  }

}
