/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MemberInfo } from '../models/member-info';
import { OptAreaDepartmentRes } from '../models/opt-area-department-res';
import { OptChemicalTypeRes } from '../models/opt-chemical-type-res';
import { OptionDataRes } from '../models/option-data-res';
import { StepBarRes } from '../models/step-bar-res';
import { TrnChRegFileRes } from '../models/trn-ch-reg-file-res';
import { TrnChRegLogRes } from '../models/trn-ch-reg-log-res';
import { TrnChRegRes } from '../models/trn-ch-reg-res';
import { TrnChRegSaveReq } from '../models/trn-ch-reg-save-req';
import { TrnChRegSubmitReq } from '../models/trn-ch-reg-submit-req';

@Injectable({
  providedIn: 'root',
})
export class ChemicalsRegisterService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiChemicalsRegisterGet
   */
  static readonly ApiChemicalsRegisterGetPath = '/api/chemicals/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChemicalsRegisterGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterGet$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<TrnChRegRes>> {

    const rb = new RequestBuilder(this.rootUrl, ChemicalsRegisterService.ApiChemicalsRegisterGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrnChRegRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiChemicalsRegisterGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterGet(params?: {
    id?: string;
  }): Observable<TrnChRegRes> {

    return this.apiChemicalsRegisterGet$Response(params).pipe(
      map((r: StrictHttpResponse<TrnChRegRes>) => r.body as TrnChRegRes)
    );
  }

  /**
   * Path part for operation apiChemicalsRegisterNewSubmitPost
   */
  static readonly ApiChemicalsRegisterNewSubmitPostPath = '/api/chemicals/register/new-submit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChemicalsRegisterNewSubmitPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiChemicalsRegisterNewSubmitPost$Response(params?: {
    body?: TrnChRegSubmitReq
  }): Observable<StrictHttpResponse<TrnChRegRes>> {

    const rb = new RequestBuilder(this.rootUrl, ChemicalsRegisterService.ApiChemicalsRegisterNewSubmitPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrnChRegRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiChemicalsRegisterNewSubmitPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiChemicalsRegisterNewSubmitPost(params?: {
    body?: TrnChRegSubmitReq
  }): Observable<TrnChRegRes> {

    return this.apiChemicalsRegisterNewSubmitPost$Response(params).pipe(
      map((r: StrictHttpResponse<TrnChRegRes>) => r.body as TrnChRegRes)
    );
  }

  /**
   * Path part for operation apiChemicalsRegisterNewSavePost
   */
  static readonly ApiChemicalsRegisterNewSavePostPath = '/api/chemicals/register/new-save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChemicalsRegisterNewSavePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiChemicalsRegisterNewSavePost$Response(params?: {
    body?: TrnChRegSaveReq
  }): Observable<StrictHttpResponse<TrnChRegRes>> {

    const rb = new RequestBuilder(this.rootUrl, ChemicalsRegisterService.ApiChemicalsRegisterNewSavePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrnChRegRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiChemicalsRegisterNewSavePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiChemicalsRegisterNewSavePost(params?: {
    body?: TrnChRegSaveReq
  }): Observable<TrnChRegRes> {

    return this.apiChemicalsRegisterNewSavePost$Response(params).pipe(
      map((r: StrictHttpResponse<TrnChRegRes>) => r.body as TrnChRegRes)
    );
  }

  /**
   * Path part for operation apiChemicalsRegisterSavePost
   */
  static readonly ApiChemicalsRegisterSavePostPath = '/api/chemicals/register/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChemicalsRegisterSavePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiChemicalsRegisterSavePost$Response(params?: {
    id?: string;
    body?: TrnChRegSaveReq
  }): Observable<StrictHttpResponse<TrnChRegRes>> {

    const rb = new RequestBuilder(this.rootUrl, ChemicalsRegisterService.ApiChemicalsRegisterSavePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrnChRegRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiChemicalsRegisterSavePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiChemicalsRegisterSavePost(params?: {
    id?: string;
    body?: TrnChRegSaveReq
  }): Observable<TrnChRegRes> {

    return this.apiChemicalsRegisterSavePost$Response(params).pipe(
      map((r: StrictHttpResponse<TrnChRegRes>) => r.body as TrnChRegRes)
    );
  }

  /**
   * Path part for operation apiChemicalsRegisterSubmitPost
   */
  static readonly ApiChemicalsRegisterSubmitPostPath = '/api/chemicals/register/submit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChemicalsRegisterSubmitPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiChemicalsRegisterSubmitPost$Response(params?: {
    id?: string;
    body?: TrnChRegSubmitReq
  }): Observable<StrictHttpResponse<TrnChRegRes>> {

    const rb = new RequestBuilder(this.rootUrl, ChemicalsRegisterService.ApiChemicalsRegisterSubmitPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrnChRegRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiChemicalsRegisterSubmitPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiChemicalsRegisterSubmitPost(params?: {
    id?: string;
    body?: TrnChRegSubmitReq
  }): Observable<TrnChRegRes> {

    return this.apiChemicalsRegisterSubmitPost$Response(params).pipe(
      map((r: StrictHttpResponse<TrnChRegRes>) => r.body as TrnChRegRes)
    );
  }

  /**
   * Path part for operation apiChemicalsRegisterRegStepBarGet
   */
  static readonly ApiChemicalsRegisterRegStepBarGetPath = '/api/chemicals/register/reg-step-bar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChemicalsRegisterRegStepBarGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterRegStepBarGet$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<StepBarRes>> {

    const rb = new RequestBuilder(this.rootUrl, ChemicalsRegisterService.ApiChemicalsRegisterRegStepBarGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StepBarRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiChemicalsRegisterRegStepBarGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterRegStepBarGet(params?: {
    id?: string;
  }): Observable<StepBarRes> {

    return this.apiChemicalsRegisterRegStepBarGet$Response(params).pipe(
      map((r: StrictHttpResponse<StepBarRes>) => r.body as StepBarRes)
    );
  }

  /**
   * Path part for operation apiChemicalsRegisterRegOptDataGet
   */
  static readonly ApiChemicalsRegisterRegOptDataGetPath = '/api/chemicals/register/reg-opt-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChemicalsRegisterRegOptDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterRegOptDataGet$Response(params?: {
  }): Observable<StrictHttpResponse<OptionDataRes>> {

    const rb = new RequestBuilder(this.rootUrl, ChemicalsRegisterService.ApiChemicalsRegisterRegOptDataGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OptionDataRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiChemicalsRegisterRegOptDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterRegOptDataGet(params?: {
  }): Observable<OptionDataRes> {

    return this.apiChemicalsRegisterRegOptDataGet$Response(params).pipe(
      map((r: StrictHttpResponse<OptionDataRes>) => r.body as OptionDataRes)
    );
  }

  /**
   * Path part for operation apiChemicalsRegisterOptAreaDepartmentGet
   */
  static readonly ApiChemicalsRegisterOptAreaDepartmentGetPath = '/api/chemicals/register/opt/area-department';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChemicalsRegisterOptAreaDepartmentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterOptAreaDepartmentGet$Response(params?: {
    mtAreaDepartmentId?: number;
  }): Observable<StrictHttpResponse<OptAreaDepartmentRes>> {

    const rb = new RequestBuilder(this.rootUrl, ChemicalsRegisterService.ApiChemicalsRegisterOptAreaDepartmentGetPath, 'get');
    if (params) {
      rb.query('mtAreaDepartmentId', params.mtAreaDepartmentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OptAreaDepartmentRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiChemicalsRegisterOptAreaDepartmentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterOptAreaDepartmentGet(params?: {
    mtAreaDepartmentId?: number;
  }): Observable<OptAreaDepartmentRes> {

    return this.apiChemicalsRegisterOptAreaDepartmentGet$Response(params).pipe(
      map((r: StrictHttpResponse<OptAreaDepartmentRes>) => r.body as OptAreaDepartmentRes)
    );
  }

  /**
   * Path part for operation apiChemicalsRegisterOptAreaDepartmentsGet
   */
  static readonly ApiChemicalsRegisterOptAreaDepartmentsGetPath = '/api/chemicals/register/opt/area-departments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChemicalsRegisterOptAreaDepartmentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterOptAreaDepartmentsGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<OptAreaDepartmentRes>>> {

    const rb = new RequestBuilder(this.rootUrl, ChemicalsRegisterService.ApiChemicalsRegisterOptAreaDepartmentsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OptAreaDepartmentRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiChemicalsRegisterOptAreaDepartmentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterOptAreaDepartmentsGet(params?: {
  }): Observable<Array<OptAreaDepartmentRes>> {

    return this.apiChemicalsRegisterOptAreaDepartmentsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OptAreaDepartmentRes>>) => r.body as Array<OptAreaDepartmentRes>)
    );
  }

  /**
   * Path part for operation apiChemicalsRegisterOptChemicalTypeGet
   */
  static readonly ApiChemicalsRegisterOptChemicalTypeGetPath = '/api/chemicals/register/opt/chemical-type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChemicalsRegisterOptChemicalTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterOptChemicalTypeGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<OptChemicalTypeRes>>> {

    const rb = new RequestBuilder(this.rootUrl, ChemicalsRegisterService.ApiChemicalsRegisterOptChemicalTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OptChemicalTypeRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiChemicalsRegisterOptChemicalTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterOptChemicalTypeGet(params?: {
  }): Observable<Array<OptChemicalTypeRes>> {

    return this.apiChemicalsRegisterOptChemicalTypeGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OptChemicalTypeRes>>) => r.body as Array<OptChemicalTypeRes>)
    );
  }

  /**
   * Path part for operation apiChemicalsRegisterGetApproverGet
   */
  static readonly ApiChemicalsRegisterGetApproverGetPath = '/api/chemicals/register/GetApprover';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChemicalsRegisterGetApproverGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterGetApproverGet$Response(params?: {
  }): Observable<StrictHttpResponse<MemberInfo>> {

    const rb = new RequestBuilder(this.rootUrl, ChemicalsRegisterService.ApiChemicalsRegisterGetApproverGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MemberInfo>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiChemicalsRegisterGetApproverGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterGetApproverGet(params?: {
  }): Observable<MemberInfo> {

    return this.apiChemicalsRegisterGetApproverGet$Response(params).pipe(
      map((r: StrictHttpResponse<MemberInfo>) => r.body as MemberInfo)
    );
  }

  /**
   * Path part for operation apiChemicalsRegisterGetApproverStartWithGet
   */
  static readonly ApiChemicalsRegisterGetApproverStartWithGetPath = '/api/chemicals/register/GetApproverStartWith';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChemicalsRegisterGetApproverStartWithGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterGetApproverStartWithGet$Response(params?: {
    startwith?: string;
  }): Observable<StrictHttpResponse<Array<MemberInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, ChemicalsRegisterService.ApiChemicalsRegisterGetApproverStartWithGetPath, 'get');
    if (params) {
      rb.query('startwith', params.startwith, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MemberInfo>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiChemicalsRegisterGetApproverStartWithGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterGetApproverStartWithGet(params?: {
    startwith?: string;
  }): Observable<Array<MemberInfo>> {

    return this.apiChemicalsRegisterGetApproverStartWithGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MemberInfo>>) => r.body as Array<MemberInfo>)
    );
  }

  /**
   * Path part for operation apiChemicalsRegisterDocumentsGet
   */
  static readonly ApiChemicalsRegisterDocumentsGetPath = '/api/chemicals/register/Documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChemicalsRegisterDocumentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterDocumentsGet$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<Array<TrnChRegFileRes>>> {

    const rb = new RequestBuilder(this.rootUrl, ChemicalsRegisterService.ApiChemicalsRegisterDocumentsGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TrnChRegFileRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiChemicalsRegisterDocumentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterDocumentsGet(params?: {
    id?: string;
  }): Observable<Array<TrnChRegFileRes>> {

    return this.apiChemicalsRegisterDocumentsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TrnChRegFileRes>>) => r.body as Array<TrnChRegFileRes>)
    );
  }

  /**
   * Path part for operation apiChemicalsRegisterUploadDocumentsPost
   */
  static readonly ApiChemicalsRegisterUploadDocumentsPostPath = '/api/chemicals/register/upload-documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChemicalsRegisterUploadDocumentsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiChemicalsRegisterUploadDocumentsPost$Response(params?: {
    id?: string;
    body?: Array<TrnChRegFileRes>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ChemicalsRegisterService.ApiChemicalsRegisterUploadDocumentsPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiChemicalsRegisterUploadDocumentsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiChemicalsRegisterUploadDocumentsPost(params?: {
    id?: string;
    body?: Array<TrnChRegFileRes>
  }): Observable<void> {

    return this.apiChemicalsRegisterUploadDocumentsPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiChemicalsRegisterActivityLogsGet
   */
  static readonly ApiChemicalsRegisterActivityLogsGetPath = '/api/chemicals/register/activity-logs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChemicalsRegisterActivityLogsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterActivityLogsGet$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<Array<TrnChRegLogRes>>> {

    const rb = new RequestBuilder(this.rootUrl, ChemicalsRegisterService.ApiChemicalsRegisterActivityLogsGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TrnChRegLogRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiChemicalsRegisterActivityLogsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChemicalsRegisterActivityLogsGet(params?: {
    id?: string;
  }): Observable<Array<TrnChRegLogRes>> {

    return this.apiChemicalsRegisterActivityLogsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TrnChRegLogRes>>) => r.body as Array<TrnChRegLogRes>)
    );
  }

}
