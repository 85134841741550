/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class ApplicationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiAppsettingGetChemicalClassificationGuideGet
   */
  static readonly ApiAppsettingGetChemicalClassificationGuideGetPath = '/api/appsetting/get-chemical-classification-guide';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppsettingGetChemicalClassificationGuideGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppsettingGetChemicalClassificationGuideGet$Response(params?: {
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationService.ApiAppsettingGetChemicalClassificationGuideGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppsettingGetChemicalClassificationGuideGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppsettingGetChemicalClassificationGuideGet(params?: {
  }): Observable<string> {

    return this.apiAppsettingGetChemicalClassificationGuideGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiAppsettingGetUserManaulGet
   */
  static readonly ApiAppsettingGetUserManaulGetPath = '/api/appsetting/get-user-manaul';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppsettingGetUserManaulGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppsettingGetUserManaulGet$Response(params?: {
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationService.ApiAppsettingGetUserManaulGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppsettingGetUserManaulGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppsettingGetUserManaulGet(params?: {
  }): Observable<string> {

    return this.apiAppsettingGetUserManaulGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiAppsettingUploadChemicalClassificationGuidePost
   */
  static readonly ApiAppsettingUploadChemicalClassificationGuidePostPath = '/api/appsetting/upload-chemical-classification-guide';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppsettingUploadChemicalClassificationGuidePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppsettingUploadChemicalClassificationGuidePost$Response(params?: {
    path?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationService.ApiAppsettingUploadChemicalClassificationGuidePostPath, 'post');
    if (params) {
      rb.query('path', params.path, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppsettingUploadChemicalClassificationGuidePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppsettingUploadChemicalClassificationGuidePost(params?: {
    path?: string;
  }): Observable<void> {

    return this.apiAppsettingUploadChemicalClassificationGuidePost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAppsettingUploadUserManaulPost
   */
  static readonly ApiAppsettingUploadUserManaulPostPath = '/api/appsetting/upload-user-manaul';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppsettingUploadUserManaulPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppsettingUploadUserManaulPost$Response(params?: {
    path?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationService.ApiAppsettingUploadUserManaulPostPath, 'post');
    if (params) {
      rb.query('path', params.path, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppsettingUploadUserManaulPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppsettingUploadUserManaulPost(params?: {
    path?: string;
  }): Observable<void> {

    return this.apiAppsettingUploadUserManaulPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
