/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountResp } from '../models/account-resp';
import { AccountWithPermisionRes } from '../models/account-with-permision-res';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiAccountPrepareFromMdmGet
   */
  static readonly ApiAccountPrepareFromMdmGetPath = '/api/account/prepare-from-mdm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountPrepareFromMdmGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountPrepareFromMdmGet$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountPrepareFromMdmGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountPrepareFromMdmGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountPrepareFromMdmGet(params?: {
  }): Observable<void> {

    return this.apiAccountPrepareFromMdmGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAccountPrepareFromMdmByEmailGet
   */
  static readonly ApiAccountPrepareFromMdmByEmailGetPath = '/api/account/prepare-from-mdm/by-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountPrepareFromMdmByEmailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountPrepareFromMdmByEmailGet$Response(params?: {
    email?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountPrepareFromMdmByEmailGetPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountPrepareFromMdmByEmailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountPrepareFromMdmByEmailGet(params?: {
    email?: string;
  }): Observable<void> {

    return this.apiAccountPrepareFromMdmByEmailGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAccountPrepareFromMdmByEmailWithSecretKeyGet
   */
  static readonly ApiAccountPrepareFromMdmByEmailWithSecretKeyGetPath = '/api/account/prepare-from-mdm/by-email-with-secret-key';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountPrepareFromMdmByEmailWithSecretKeyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountPrepareFromMdmByEmailWithSecretKeyGet$Response(params?: {
    email?: string;
    mdmSecretKey?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountPrepareFromMdmByEmailWithSecretKeyGetPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
      rb.query('mdmSecretKey', params.mdmSecretKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountPrepareFromMdmByEmailWithSecretKeyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountPrepareFromMdmByEmailWithSecretKeyGet(params?: {
    email?: string;
    mdmSecretKey?: string;
  }): Observable<void> {

    return this.apiAccountPrepareFromMdmByEmailWithSecretKeyGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAccountGet
   */
  static readonly ApiAccountGetPath = '/api/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGet$Response(params?: {
  }): Observable<StrictHttpResponse<AccountWithPermisionRes>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountWithPermisionRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGet(params?: {
  }): Observable<AccountWithPermisionRes> {

    return this.apiAccountGet$Response(params).pipe(
      map((r: StrictHttpResponse<AccountWithPermisionRes>) => r.body as AccountWithPermisionRes)
    );
  }

  /**
   * Path part for operation apiAccountGetByEmailGet
   */
  static readonly ApiAccountGetByEmailGetPath = '/api/account/get-by-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountGetByEmailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetByEmailGet$Response(params?: {
    email?: string;
  }): Observable<StrictHttpResponse<AccountWithPermisionRes>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountGetByEmailGetPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountWithPermisionRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountGetByEmailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetByEmailGet(params?: {
    email?: string;
  }): Observable<AccountWithPermisionRes> {

    return this.apiAccountGetByEmailGet$Response(params).pipe(
      map((r: StrictHttpResponse<AccountWithPermisionRes>) => r.body as AccountWithPermisionRes)
    );
  }

  /**
   * Path part for operation apiAccountGetByIdGet
   */
  static readonly ApiAccountGetByIdGetPath = '/api/account/get-by-id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountGetByIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetByIdGet$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<AccountResp>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountGetByIdGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResp>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountGetByIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetByIdGet(params?: {
    id?: string;
  }): Observable<AccountResp> {

    return this.apiAccountGetByIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResp>) => r.body as AccountResp)
    );
  }

  /**
   * Path part for operation apiAccountStartWithGet
   */
  static readonly ApiAccountStartWithGetPath = '/api/account/start-with';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountStartWithGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountStartWithGet$Response(params?: {
    startwith?: string;
    max?: number;
  }): Observable<StrictHttpResponse<Array<AccountResp>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountStartWithGetPath, 'get');
    if (params) {
      rb.query('startwith', params.startwith, {});
      rb.query('max', params.max, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccountResp>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountStartWithGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountStartWithGet(params?: {
    startwith?: string;
    max?: number;
  }): Observable<Array<AccountResp>> {

    return this.apiAccountStartWithGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AccountResp>>) => r.body as Array<AccountResp>)
    );
  }

  /**
   * Path part for operation apiAccountListPost
   */
  static readonly ApiAccountListPostPath = '/api/account/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountListPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAccountListPost$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<Array<AccountResp>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccountResp>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountListPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAccountListPost(params?: {
    body?: Array<string>
  }): Observable<Array<AccountResp>> {

    return this.apiAccountListPost$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AccountResp>>) => r.body as Array<AccountResp>)
    );
  }

}
