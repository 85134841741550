/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AppDataResp } from '../models/app-data-resp';

@Injectable({
  providedIn: 'root',
})
export class AppService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiAppDataGet
   */
  static readonly ApiAppDataGetPath = '/api/app/data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppDataGet$Response(params?: {
  }): Observable<StrictHttpResponse<AppDataResp>> {

    const rb = new RequestBuilder(this.rootUrl, AppService.ApiAppDataGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AppDataResp>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppDataGet(params?: {
  }): Observable<AppDataResp> {

    return this.apiAppDataGet$Response(params).pipe(
      map((r: StrictHttpResponse<AppDataResp>) => r.body as AppDataResp)
    );
  }

  /**
   * Path part for operation apiAppReloadConfsGet
   */
  static readonly ApiAppReloadConfsGetPath = '/api/app/reload-confs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppReloadConfsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppReloadConfsGet$Response(params?: {
    secret?: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AppService.ApiAppReloadConfsGetPath, 'get');
    if (params) {
      rb.query('secret', params.secret, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppReloadConfsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppReloadConfsGet(params?: {
    secret?: string;
  }): Observable<boolean> {

    return this.apiAppReloadConfsGet$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiAppRegisterDefaultEmailTemplateGet
   */
  static readonly ApiAppRegisterDefaultEmailTemplateGetPath = '/api/app/register-default-email-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppRegisterDefaultEmailTemplateGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppRegisterDefaultEmailTemplateGet$Response(params?: {
    secret?: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AppService.ApiAppRegisterDefaultEmailTemplateGetPath, 'get');
    if (params) {
      rb.query('secret', params.secret, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppRegisterDefaultEmailTemplateGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppRegisterDefaultEmailTemplateGet(params?: {
    secret?: string;
  }): Observable<boolean> {

    return this.apiAppRegisterDefaultEmailTemplateGet$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
