/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AreaDepartmentRes } from '../models/area-department-res';
import { AreaEditItemReq } from '../models/area-edit-item-req';
import { AreaQueryCriteriaReq } from '../models/area-query-criteria-req';
import { AreaRes } from '../models/area-res';
import { TreeDataRes } from '../models/tree-data-res';

@Injectable({
  providedIn: 'root',
})
export class MasterDataAreaService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiMasterdataAreaQueryPost
   */
  static readonly ApiMasterdataAreaQueryPostPath = '/api/masterdata/area/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataAreaQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataAreaQueryPost$Response(params?: {
    body?: AreaQueryCriteriaReq
  }): Observable<StrictHttpResponse<Array<AreaRes>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataAreaService.ApiMasterdataAreaQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AreaRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataAreaQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataAreaQueryPost(params?: {
    body?: AreaQueryCriteriaReq
  }): Observable<Array<AreaRes>> {

    return this.apiMasterdataAreaQueryPost$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AreaRes>>) => r.body as Array<AreaRes>)
    );
  }

  /**
   * Path part for operation apiMasterdataAreaPut
   */
  static readonly ApiMasterdataAreaPutPath = '/api/masterdata/area';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataAreaPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataAreaPut$Response(params?: {
    id?: number;
    body?: AreaEditItemReq
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataAreaService.ApiMasterdataAreaPutPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataAreaPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataAreaPut(params?: {
    id?: number;
    body?: AreaEditItemReq
  }): Observable<void> {

    return this.apiMasterdataAreaPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiMasterdataAreaPost
   */
  static readonly ApiMasterdataAreaPostPath = '/api/masterdata/area';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataAreaPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataAreaPost$Response(params?: {
    body?: AreaEditItemReq
  }): Observable<StrictHttpResponse<AreaRes>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataAreaService.ApiMasterdataAreaPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AreaRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataAreaPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterdataAreaPost(params?: {
    body?: AreaEditItemReq
  }): Observable<AreaRes> {

    return this.apiMasterdataAreaPost$Response(params).pipe(
      map((r: StrictHttpResponse<AreaRes>) => r.body as AreaRes)
    );
  }

  /**
   * Path part for operation apiMasterdataAreaDelete
   */
  static readonly ApiMasterdataAreaDeletePath = '/api/masterdata/area';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataAreaDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataAreaDelete$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataAreaService.ApiMasterdataAreaDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataAreaDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataAreaDelete(params?: {
    id?: number;
  }): Observable<void> {

    return this.apiMasterdataAreaDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiMasterdataAreaAreaStoreTreesForCurrentUserGet
   */
  static readonly ApiMasterdataAreaAreaStoreTreesForCurrentUserGetPath = '/api/masterdata/area/area-store-trees/for-current-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataAreaAreaStoreTreesForCurrentUserGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataAreaAreaStoreTreesForCurrentUserGet$Response(params?: {
    includeInactived?: boolean;
  }): Observable<StrictHttpResponse<Array<TreeDataRes>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataAreaService.ApiMasterdataAreaAreaStoreTreesForCurrentUserGetPath, 'get');
    if (params) {
      rb.query('includeInactived', params.includeInactived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TreeDataRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataAreaAreaStoreTreesForCurrentUserGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataAreaAreaStoreTreesForCurrentUserGet(params?: {
    includeInactived?: boolean;
  }): Observable<Array<TreeDataRes>> {

    return this.apiMasterdataAreaAreaStoreTreesForCurrentUserGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TreeDataRes>>) => r.body as Array<TreeDataRes>)
    );
  }

  /**
   * Path part for operation apiMasterdataAreaAreaDepartmentTreesForCurrentUserGet
   */
  static readonly ApiMasterdataAreaAreaDepartmentTreesForCurrentUserGetPath = '/api/masterdata/area/area-department-trees/for-current-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataAreaAreaDepartmentTreesForCurrentUserGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataAreaAreaDepartmentTreesForCurrentUserGet$Response(params?: {
    includeInactived?: boolean;
  }): Observable<StrictHttpResponse<Array<TreeDataRes>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataAreaService.ApiMasterdataAreaAreaDepartmentTreesForCurrentUserGetPath, 'get');
    if (params) {
      rb.query('includeInactived', params.includeInactived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TreeDataRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataAreaAreaDepartmentTreesForCurrentUserGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataAreaAreaDepartmentTreesForCurrentUserGet(params?: {
    includeInactived?: boolean;
  }): Observable<Array<TreeDataRes>> {

    return this.apiMasterdataAreaAreaDepartmentTreesForCurrentUserGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TreeDataRes>>) => r.body as Array<TreeDataRes>)
    );
  }

  /**
   * Path part for operation apiMasterdataAreaAreaDepartmentTreesAllGet
   */
  static readonly ApiMasterdataAreaAreaDepartmentTreesAllGetPath = '/api/masterdata/area/area-department-trees/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataAreaAreaDepartmentTreesAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataAreaAreaDepartmentTreesAllGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<TreeDataRes>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataAreaService.ApiMasterdataAreaAreaDepartmentTreesAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TreeDataRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataAreaAreaDepartmentTreesAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataAreaAreaDepartmentTreesAllGet(params?: {
  }): Observable<Array<TreeDataRes>> {

    return this.apiMasterdataAreaAreaDepartmentTreesAllGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TreeDataRes>>) => r.body as Array<TreeDataRes>)
    );
  }

  /**
   * Path part for operation apiMasterdataAreaOptDepartmentsGet
   */
  static readonly ApiMasterdataAreaOptDepartmentsGetPath = '/api/masterdata/area/opt/departments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterdataAreaOptDepartmentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataAreaOptDepartmentsGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<AreaDepartmentRes>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterDataAreaService.ApiMasterdataAreaOptDepartmentsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AreaDepartmentRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterdataAreaOptDepartmentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterdataAreaOptDepartmentsGet(params?: {
  }): Observable<Array<AreaDepartmentRes>> {

    return this.apiMasterdataAreaOptDepartmentsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AreaDepartmentRes>>) => r.body as Array<AreaDepartmentRes>)
    );
  }

}
