import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataResp } from '../api/models';
import { AppService } from '../api/services';
import { appData } from '../utils/appInitializer';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private http: HttpClient;
  private settings: AppDataResp | undefined;

  constructor(private readonly httpHandler: HttpBackend) {
    this.http = new HttpClient(httpHandler);
  }

  //init(configUrl: string): Promise<boolean> {

  //  const appService = new AppService({ rootUrl: configUrl }, this.http);

  //  return new Promise<boolean>((resolve, reject) => {
  //    appService.apiAppDataGet().pipe().subscribe(value => {
  //      this.settings = value;
  //      Object.assign(appData, value);
  //      resolve(true);
  //    },
  //      (error) => {
  //        reject(error);
  //      });
  //  });
  //}

  getSettingObject() {
    return appData;
  }
}
