/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OrganizationRes } from '../models/organization-res';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiOrganizationPrepareFromMdmByIdGet
   */
  static readonly ApiOrganizationPrepareFromMdmByIdGetPath = '/api/organization/prepare-from-mdm/by-id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationPrepareFromMdmByIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationPrepareFromMdmByIdGet$Response(params?: {
    organizationId?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationPrepareFromMdmByIdGetPath, 'get');
    if (params) {
      rb.query('organizationId', params.organizationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationPrepareFromMdmByIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationPrepareFromMdmByIdGet(params?: {
    organizationId?: number;
  }): Observable<void> {

    return this.apiOrganizationPrepareFromMdmByIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiOrganizationPrepareFromMdmByIdWithSecretKeyGet
   */
  static readonly ApiOrganizationPrepareFromMdmByIdWithSecretKeyGetPath = '/api/organization/prepare-from-mdm/by-id-with-secret-key';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationPrepareFromMdmByIdWithSecretKeyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationPrepareFromMdmByIdWithSecretKeyGet$Response(params?: {
    organizationId?: number;
    mdmSecretKey?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationPrepareFromMdmByIdWithSecretKeyGetPath, 'get');
    if (params) {
      rb.query('organizationId', params.organizationId, {});
      rb.query('mdmSecretKey', params.mdmSecretKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationPrepareFromMdmByIdWithSecretKeyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationPrepareFromMdmByIdWithSecretKeyGet(params?: {
    organizationId?: number;
    mdmSecretKey?: string;
  }): Observable<void> {

    return this.apiOrganizationPrepareFromMdmByIdWithSecretKeyGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiOrganizationStartWithGet
   */
  static readonly ApiOrganizationStartWithGetPath = '/api/organization/start-with';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationStartWithGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationStartWithGet$Response(params?: {
    startwith?: string;
    max?: number;
  }): Observable<StrictHttpResponse<Array<OrganizationRes>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationStartWithGetPath, 'get');
    if (params) {
      rb.query('startwith', params.startwith, {});
      rb.query('max', params.max, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrganizationRes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationStartWithGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationStartWithGet(params?: {
    startwith?: string;
    max?: number;
  }): Observable<Array<OrganizationRes>> {

    return this.apiOrganizationStartWithGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrganizationRes>>) => r.body as Array<OrganizationRes>)
    );
  }

}
