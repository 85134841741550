/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HomeContentReq } from '../models/home-content-req';
import { HomeDataRes } from '../models/home-data-res';

@Injectable({
  providedIn: 'root',
})
export class HomeApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiHomeContentGet
   */
  static readonly ApiHomeContentGetPath = '/api/home/content';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeContentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHomeContentGet$Response(params?: {
  }): Observable<StrictHttpResponse<HomeDataRes>> {

    const rb = new RequestBuilder(this.rootUrl, HomeApiService.ApiHomeContentGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HomeDataRes>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiHomeContentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHomeContentGet(params?: {
  }): Observable<HomeDataRes> {

    return this.apiHomeContentGet$Response(params).pipe(
      map((r: StrictHttpResponse<HomeDataRes>) => r.body as HomeDataRes)
    );
  }

  /**
   * Path part for operation apiHomeContentContactInfoPost
   */
  static readonly ApiHomeContentContactInfoPostPath = '/api/home/content/contact-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeContentContactInfoPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHomeContentContactInfoPost$Response(params?: {
    body?: HomeContentReq
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, HomeApiService.ApiHomeContentContactInfoPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiHomeContentContactInfoPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHomeContentContactInfoPost(params?: {
    body?: HomeContentReq
  }): Observable<void> {

    return this.apiHomeContentContactInfoPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiHomeContentAnnouncementPost
   */
  static readonly ApiHomeContentAnnouncementPostPath = '/api/home/content/announcement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHomeContentAnnouncementPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHomeContentAnnouncementPost$Response(params?: {
    body?: HomeContentReq
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, HomeApiService.ApiHomeContentAnnouncementPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiHomeContentAnnouncementPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHomeContentAnnouncementPost(params?: {
    body?: HomeContentReq
  }): Observable<void> {

    return this.apiHomeContentAnnouncementPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
