/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class StockStickerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation stockSticker3X5DataSetKeyDotPdfGet
   */
  static readonly StockSticker3X5DataSetKeyDotPdfGetPath = '/stock/sticker/3x5/{dataSetKeyDotPdf}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stockSticker3X5DataSetKeyDotPdfGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  stockSticker3X5DataSetKeyDotPdfGet$Response(params: {
    dataSetKeyDotPdf: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StockStickerService.StockSticker3X5DataSetKeyDotPdfGetPath, 'get');
    if (params) {
      rb.path('dataSetKeyDotPdf', params.dataSetKeyDotPdf, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stockSticker3X5DataSetKeyDotPdfGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stockSticker3X5DataSetKeyDotPdfGet(params: {
    dataSetKeyDotPdf: string;
  }): Observable<void> {

    return this.stockSticker3X5DataSetKeyDotPdfGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stockStickerTest3X5DataSetKeyDotPdfGet
   */
  static readonly StockStickerTest3X5DataSetKeyDotPdfGetPath = '/stock/sticker/test3x5/{dataSetKeyDotPdf}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stockStickerTest3X5DataSetKeyDotPdfGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  stockStickerTest3X5DataSetKeyDotPdfGet$Response(params: {
    dataSetKeyDotPdf: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StockStickerService.StockStickerTest3X5DataSetKeyDotPdfGetPath, 'get');
    if (params) {
      rb.path('dataSetKeyDotPdf', params.dataSetKeyDotPdf, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stockStickerTest3X5DataSetKeyDotPdfGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stockStickerTest3X5DataSetKeyDotPdfGet(params: {
    dataSetKeyDotPdf: string;
  }): Observable<void> {

    return this.stockStickerTest3X5DataSetKeyDotPdfGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stockSticker9X10DataSetKeyDotPdfGet
   */
  static readonly StockSticker9X10DataSetKeyDotPdfGetPath = '/stock/sticker/9x10/{dataSetKeyDotPdf}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stockSticker9X10DataSetKeyDotPdfGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  stockSticker9X10DataSetKeyDotPdfGet$Response(params: {
    dataSetKeyDotPdf: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StockStickerService.StockSticker9X10DataSetKeyDotPdfGetPath, 'get');
    if (params) {
      rb.path('dataSetKeyDotPdf', params.dataSetKeyDotPdf, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stockSticker9X10DataSetKeyDotPdfGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stockSticker9X10DataSetKeyDotPdfGet(params: {
    dataSetKeyDotPdf: string;
  }): Observable<void> {

    return this.stockSticker9X10DataSetKeyDotPdfGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stockStickerTest9X10DataSetKeyDotPdfGet
   */
  static readonly StockStickerTest9X10DataSetKeyDotPdfGetPath = '/stock/sticker/test9x10/{dataSetKeyDotPdf}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stockStickerTest9X10DataSetKeyDotPdfGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  stockStickerTest9X10DataSetKeyDotPdfGet$Response(params: {
    dataSetKeyDotPdf: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StockStickerService.StockStickerTest9X10DataSetKeyDotPdfGetPath, 'get');
    if (params) {
      rb.path('dataSetKeyDotPdf', params.dataSetKeyDotPdf, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stockStickerTest9X10DataSetKeyDotPdfGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stockStickerTest9X10DataSetKeyDotPdfGet(params: {
    dataSetKeyDotPdf: string;
  }): Observable<void> {

    return this.stockStickerTest9X10DataSetKeyDotPdfGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stockStickerBarcodeCodeGet
   */
  static readonly StockStickerBarcodeCodeGetPath = '/stock/sticker/barcode/{code}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stockStickerBarcodeCodeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  stockStickerBarcodeCodeGet$Response(params: {
    code: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StockStickerService.StockStickerBarcodeCodeGetPath, 'get');
    if (params) {
      rb.path('code', params.code, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stockStickerBarcodeCodeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stockStickerBarcodeCodeGet(params: {
    code: string;
  }): Observable<void> {

    return this.stockStickerBarcodeCodeGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
